 import React from "react";
import {Trans, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import {createMarkup} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";

import CarouselWrapper from '../../../components/carousel/CarouselWrapper';
import Project01 from './images/project/08-2-project-01a.jpg';
import Project02 from './images/project/08-2-project-02a.jpg';
import Project03 from './images/project/08-2-project-03a.jpg';
import Project04 from './images/project/08-2-project-04a.jpg';
import Project05 from './images/project/08-2-project-05a.jpg';
import Project07 from './images/project/08-2-project-07a.jpg';
import Project08 from './images/project/08-2-project-08a.jpg';
import Project09 from './images/project/Tommy Hilfiger_b-1440x520.png';

import staffLockerRoom from './images/applications/staff-locker-rooms.jpg';
import merchDisplays from './images/applications/merchandise.jpg';
import chargingLockers from './images/applications/charging-lockers.jpg';
import customerLockers from './images/applications/customer-lockers.jpg';

const IndexPage = () => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const projectImages = [
    {
      original: Project09,
      originalAlt: 'Tommy Hilfiger',
      thumbnailAlt: 'Tommy Hilfiger thumbnail',
      thumbnail: Project09,
      thumbnailLabel: 'Tommy Hilfiger',
      productLink: returnLocaleURL(sl, '/products/')

    },
    {
      original: Project01,
      originalAlt: 'Digilock 4G Electronic Locks Installed at La Maison Simons',
      thumbnailAlt: 'Digilock 4G Electronic Locks Installed at La Maison Simons thumbnail',
      thumbnail: Project01,
      thumbnailLabel: 'La Maison Simons',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project02,
      originalAlt: 'Joann Fabrics keypad lockers',
      thumbnailAlt: 'Joann Fabrics keypad lockers thumbnail',
      thumbnail: Project02,
      thumbnailLabel: 'Joann Fabrics',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project03,
      originalAlt: 'Estonian Mall keypad lockers',
      thumbnailAlt: 'Estonian Mall keypad lockers thumbnail',
      thumbnail: Project03,
      thumbnailLabel: 'Estonian Mall',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project04,
      originalAlt: 'Bubble Stitch keypad lockers',
      thumbnailAlt: 'Bubble Stitch keypad lockers thumbnail',
      thumbnail: Project04,
      thumbnailLabel: 'Bubble Stitch',
      productLink: returnLocaleURL(sl, '/products/electronic-locks/sola/')
    },
    {
      original: Project05,
      originalAlt: 'Unilever keypad lockers',
      thumbnailAlt: 'Unilever keypad lockers thumbnail',
      thumbnail: Project05,
      thumbnailLabel: 'Unilever',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project07,
      originalAlt: 'Riga Mall keypad lockers',
      thumbnailAlt: 'Riga Mall keypad lockers thumbnail',
      thumbnail: Project07,
      thumbnailLabel: 'Riga Mall',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project08,
      originalAlt: 'Walgreens Employee Lockers with Cue Electronic Locks',
      thumbnailAlt: 'Walgreens Employee Lockers with Cue Electronic Locks thumbnail',
      thumbnail: Project08,
      thumbnailLabel: 'Walgreens',
      productLink: returnLocaleURL(sl, '/products/')

    }
  ];
  const projectAsides = [
    '<span>the netherlands</span><br>' +
    '<h2>Tommy Hilfiger</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Sola Keypad</a></p>',

    '<span>canada</span><br>' +
    '<h2>La Maison Simons</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Joann Fabrics</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Lockup Lockers Keypad</a></p>',

    '<span>estonia</span><br>' +
    '<h2>Estonian Mall</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Bubble Stitch</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Classic Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Unilever</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>latvia</span><br>' +
    '<h2>Riga Mall</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Walgreens</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Lockup Lockers Keypad</a></p>'
  ];
  const ProjectAside = _ => <aside className={'dynamic-aside'}
                                   dangerouslySetInnerHTML={createMarkup(projectAsides[0])} />;
  const solutions = [
    {
      img: staffLockerRoom,
      label: 'retail_locker_rooms',
      alt: 'staff_locker_rooms',
      bg: 'light'
    },
    {
      img: merchDisplays,
      label: 'merch_displays',
      alt: 'merch_displays',
      bg: 'light'
    },
    {
      img: chargingLockers,
      label: 'juicebar_charging_lockers',
      alt: 'juicebar_charging_lockers',
      bg: 'light'
    },
    {
      img: customerLockers,
      label: 'customer_lockers',
      alt: 'customer_lockers',
      bg: 'dark'
    }
  ]

  return (
      <Layout>
        <Seo title={t('solutions_retail')}
        description={t('des_solutions_retail')}/>
        <div className="solutions">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-retail.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Retail"
            />
            <h1>
              <Trans>
                retail
              </Trans>
              <span>
                <Trans>
                  retail_hero_subhead
                </Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>retail_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>retail_subhero_list_l1</Trans></li>
                  <li><Trans>retail_subhero_list_l2</Trans></li>
                  <li><Trans>retail_subhero_list_l3</Trans></li>
                  <li><Trans>retail_subhero_list_l4</Trans></li>
                  <li><Trans>retail_subhero_list_l5</Trans></li>
                  <li><Trans>retail_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/retail.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="workspace office"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup
              label={'retail_lineup_label'}
              showCurve={false}
              showAspire={true}
              showVersa={true}
              showOrbit={true}
              showRange={true}
              showCleo={true}
              showCTA={true}
          />
          <section className="industry-carousel">
            <div className="container">
              <h2>
                <Trans>
                  retail_carousel
                </Trans>
              </h2>
              <CarouselWrapper
                  items={projectImages}
                  asides={projectAsides}
                  projectAside={<ProjectAside />}
                  showThumbnails={true}
                  showBullets={true}
                  dynamicAside={true}
                  allowImageClick={false}
              />
            </div>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2>
                  <Trans>
                    retail_convenient
                  </Trans>
                </h2>
                <p>
                  <Trans>retail_copy</Trans>
                </p>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/retail2.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="retail locker"
                />
              </div>
            </div>
          </section>
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless</Trans></h2>
              <Solution items={solutions} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
